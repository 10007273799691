import React from "react";

import "./Icon.css";

/**
 * @param {string} className
 * @param {string} backgroundImage
 * @param {string} size
 * @param {string} backgroundColor
 * @param {string} backgroundColorHover
 * @param {string} backgroundColorIcon
 * @param {string} backgroundColorIconHover
 * @param {function} onClick
 * @param {object} style
 * @param {object} props
 * @returns {React.Component}
 * 
 * @description Afficher une icône
 * 
 * @example
 * <Icon backgroundImage="https://via.placeholder.com/150" />
 * <Icon backgroundImage="https://via.placeholder.com/150" className="maClasse" />
 * <Icon backgroundImage="https://via.placeholder.com/150" style={{color: "#353434"}} />
 */
function Icon({ className, backgroundImage, size, backgroundColor, backgroundColorHover, backgroundColorIcon, backgroundColorIconHover, onClick, style, ...props }) {
	return (
		<div
			{...props}
			className={`ocade-icon ${className}`}
			onClick={onClick}
			style={{
				"--background-color": backgroundColor,
				"--background-color-hover": backgroundColorHover,
				"--size": size,
				"cursor": onClick && onClick !== Icon.defaultProps.onClick ? "pointer" : "default",
				...style
			}}
		>
			<span
				style={{
					"--background-color-icon": backgroundColorIcon,
					"--background-color-icon-hover": backgroundColorIconHover,
					"backgroundImage": `url(${backgroundImage})`,
					"WebkitMaskImage": `url(${backgroundImage})`
				}}
			></span>
		</div>
	);
}

Icon.defaultProps = {
	className: "",
	size: "1.5rem",
	backgroundColor: "var(--color-gris-2)",
	backgroundColorHover: "var(--color-bleu)",
	backgroundColorIcon: "var(--color-blanc)",
	backgroundColorIconHover: "var(--color-blanc)",
	backgroundImage: null,
	onClick: () => {},
	style: {}
};

export default Icon;
