// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ocade-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size); /* Donner par le composant Icon */
  height: var(--size); /* Donner par le composant Icon */
  margin: .2rem;
  padding: 0.35rem;
  background-size: 0;
  background-color: var(--background-color);
  border-radius: 6px;
}

.ocade-icon:hover {
  background-color: var(--background-color-hover);
}

.ocade-icon span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size); /* Donner par le composant Icon */
  height: var(--size); /* Donner par le composant Icon */
  background: var(--background-color-icon) !important;
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: calc(var(--size) - var(--size) / 2.5 ); /* Donner par le composant Icon */
  opacity: .9;
}

.ocade-icon:hover span {
  background: var(--background-color-icon-hover) !important;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/composants/Icon/Icon.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB,EAAE,iCAAiC;EACrD,mBAAmB,EAAE,iCAAiC;EACtD,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB,EAAE,iCAAiC;EACrD,mBAAmB,EAAE,iCAAiC;EACtD,mDAAmD;EACnD,4BAA4B;EAC5B,sBAAsB;EACtB,iDAAiD,EAAE,iCAAiC;EACpF,WAAW;AACb;;AAEA;EACE,yDAAyD;EACzD,UAAU;AACZ","sourcesContent":[".ocade-icon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: var(--size); /* Donner par le composant Icon */\n  height: var(--size); /* Donner par le composant Icon */\n  margin: .2rem;\n  padding: 0.35rem;\n  background-size: 0;\n  background-color: var(--background-color);\n  border-radius: 6px;\n}\n\n.ocade-icon:hover {\n  background-color: var(--background-color-hover);\n}\n\n.ocade-icon span {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: var(--size); /* Donner par le composant Icon */\n  height: var(--size); /* Donner par le composant Icon */\n  background: var(--background-color-icon) !important;\n  mask-position: center center;\n  mask-repeat: no-repeat;\n  mask-size: calc(var(--size) - var(--size) / 2.5 ); /* Donner par le composant Icon */\n  opacity: .9;\n}\n\n.ocade-icon:hover span {\n  background: var(--background-color-icon-hover) !important;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
