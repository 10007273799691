import React, { useEffect, useState, useRef } from "react";
import { useStore } from "./Store";

import ReactMarkdown from "react-markdown";

import Textarea from "./composants/Textarea/Textarea";
import Button from "./composants/Button/Button";
import Icon from "./composants/Icon/Icon";

import ChatbotUrl from "./svgs/Chatbot.svg";
import DeleteUrl from "./svgs/Delete.svg";
import FullScreenUrl from "./svgs/FullScreen.svg";
import ReduceScreenUrl from "./svgs/ReduceScreen.svg";
import ArrowDownwardUrl from "./svgs/ArrowDownward.svg";
import CopyUrl from "./svgs/Copy.svg";

function App(props) {
  const { state, initStore, updStore } = useStore();
  const [isBegin, setIsBegin] = useState(false); // L'utilisateur à déja commencé à intéragir avec le chatbot ?
  const [question, setQuestion] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [isTextCopied, setIsTextCopied] = useState(false);
  const [hasAcceptedRgpd, setHasAcceptedRgpd] = useState(false); // RGPD consentement
  const textareaRef = useRef(null);
  const bottomRef = useRef(null);
  const h1Ref = useRef(null);
  const footerRef = useRef(null);
  const chatbotRef = useRef(null);

  useEffect(() => updateRootCssVariable(), [state]);

  // Vérification de la RGPD dès le chargement
  useEffect(() => {
    const rgpdAccepted = localStorage.getItem("rgpdAccepted");
    if (rgpdAccepted) {
      setHasAcceptedRgpd(true);
    }
  }, []);

  const handleRejectRgpd = () => {
    closeChatbot();
  };

  // Fonction pour obtenir l'adresse IP de l'utilisateur via une API externe
  const fetchUserIp = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Erreur lors de la récupération de l'IP :", error);
      return "IP inconnue"; // En cas d'erreur, retourner une IP par défaut
    }
  };

  const handleAcceptRgpd = async () => {
    const ip = await fetchUserIp(); // Remplacez par la méthode correcte pour obtenir l'IP de l'utilisateur

    const currentDate = new Date();
    const rgpdInfo = {
      date: currentDate.toLocaleDateString(),
      time: currentDate.toLocaleTimeString(),
      ip: ip,
    };

    // Stocker les infos RGPD dans le localStorage
    localStorage.setItem("rgpdAccepted", "true");
    localStorage.setItem("rgpdInfo", JSON.stringify(rgpdInfo));

    setHasAcceptedRgpd(true);

    // Envoyer les infos au serveur pour enregistrement
    await fetch("/wp-json/ocade-chatbot/v1/rgpd-accept/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(rgpdInfo),
    });
  };

  useEffect(() => {
    initSessionStorage();
    const handleKeyDown = (event) => (event.key === "Escape" ? closeChatbot() : null);
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);
  useEffect(() => {
    function adjustMessagesWrapperHeight() {
      if (chatbotRef.current && h1Ref.current && footerRef.current && bottomRef.current) {
        const chatbotHeight = chatbotRef.current.offsetHeight;
        const h1Height = h1Ref.current.offsetHeight;
        const footerHeight = footerRef.current.offsetHeight;
        const newMessagesWrapperHeight = chatbotHeight - h1Height - footerHeight;
        bottomRef.current.style.height = `${newMessagesWrapperHeight}px`;
      }
    }
    // Ajustez la hauteur à l'initialisation
    adjustMessagesWrapperHeight();
    // Ajustez la hauteur lors du redimensionnement de la fenêtre
    window.addEventListener("resize", adjustMessagesWrapperHeight);
    // Nettoyage de l'effet
    return () => window.removeEventListener("resize", adjustMessagesWrapperHeight);
  }, [state.isChatbotFullScreen, state.isChatbotOpen]);
  useEffect(() => {
    setTimeout(() => {
      if (bottomRef.current) {
        const scrollHeight = bottomRef.current.scrollHeight;
        bottomRef.current.scrollTop = scrollHeight;
      }
    }, 100);
  }, [state.sessionStorage, waiting]);
  useEffect(() => {
    if (state.isChatbotOpen && window.innerWidth >= 600) textareaRef.current && textareaRef.current.focus();
  }, [state.isChatbotOpen]);
  useEffect(() => {
    const scrollContainer = bottomRef.current;
    if (scrollContainer) scrollContainer.addEventListener("scroll", checkScrollPosition);
    return () => {
      if (scrollContainer) scrollContainer.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsTextCopied(true);
      setTimeout(() => setIsTextCopied(false), 2000);
    });
  };

  // Fonction pour faire défiler jusqu'en bas
  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
    }
  };

  const checkScrollPosition = () => {
    if (!bottomRef.current) return;
    const isAtBottom = bottomRef.current.scrollHeight - bottomRef.current.scrollTop === bottomRef.current.clientHeight;
    setShowScrollToBottom(!isAtBottom);
  };
  const openChatbot = () => updStore({ ...state, isChatbotOpen: true }); // Ouvre le chatbot
  const closeChatbot = () => updStore({ ...state, isChatbotOpen: false }); // Ferme le chatbot
  const agrandirChatbot = () => updStore({ ...state, isChatbotFullScreen: true }); // Agrandit le chatbot
  const reduireChatbot = () => updStore({ ...state, isChatbotFullScreen: false }); // Réduit le chatbot
  const initSessionStorage = () => {
    // Charge la sessionsStorage dans le store si elle existe et ajout les props au store
    const session = sessionStorage.getItem("ocade-chatbots");
    const parseProps = {
      ...props,
      profilGptId: parseInt(props.profilGptId),
      apiKeyId: parseInt(props.apiKeyId),
    };
    if (session)
      initStore({
        ...state,
        ...parseProps,
        sessionStorage: JSON.parse(session),
      });
    else initStore({ ...state, ...parseProps });
  };

  const questionsStarters = () => {
    if (isBegin || typeof state.questionsStarter1 === "undefined") return;

    const questions = [state.questionsStarter1, state.questionsStarter2, state.questionsStarter3, state.questionsStarter4];

    const hasQuestions = questions.some((question) => question?.length);

    if (!hasQuestions) return setIsBegin(true);

    // Générer les boutons de questions de démarrage si des questions sont présentes.
    return (
      <>
        <p className="questions-frequentes">Questions Fréquentes !</p>
        {questions
          .filter((Q) => Q?.length) // Filtrer les questions non nulles
          .map((Q, index) => (
            <Button
              key={index}
              className="btn-starter"
              title="Poser la question !"
              design="info"
              size="2rem"
              onClick={() => {
                setQuestion(Q);
                askGPT(Q);
              }}>
              {Q}
            </Button>
          ))}
      </>
    );
  };

  const updateRootCssVariable = () => {
    // Obtient une référence à l'élément :root (le document HTML)
    const root = document.documentElement;

    // Définit ou met à jour la variable CSS --info-fonce
    root.style.setProperty("--info-fonce", state.iconColor1 || "#000"); // Remplacez #votreCouleur par la valeur souhaitée
    root.style.setProperty("--info-clair", state.iconColor1 || "#000"); // Remplacez #votreCouleur par la valeur souhaitée
    // --success-fonce
    root.style.setProperty("--success-fonce", state.iconColor2 || "#000"); // Remplacez #votreCouleur par la valeur souhaitée
    root.style.setProperty("--success-clair", state.iconColor2 || "#000"); // Remplacez #votreCouleur par la valeur souhaitée
  };

  const askGPT = async (Q = null) => {
    // Si Q est un objet alors c'est un event (bouton envoyer), on veut récupérer le champs input de l'élément juste avant (Teaxtearea)
    if (Q && typeof Q === "object") Q = Q.target.previousElementSibling.children[1].value; // Récupérer la valeur du champs input Si mal passé.
    if (waiting || (!question.length && !Q?.length)) return;
    setIsBegin(true);
    let session = state.sessionStorage.find((session) => session.id == state.profilGptId); // Element du tableau sessions Storage correspondant à la session en cours
    if (session)
      session.messages.push({
        question: Q || question,
      });
    // Ajout de la question dans la session en cours
    else {
      session = {
        id: state.profilGptId,
        messages: [{ question: Q || question }],
        apiKeyId: state.apiKeyId,
        profilGptId: state.profilGptId,
      };
      state.sessionStorage.push(session); // Ajout de la session dans le tableau sessions Storage
    }
    setQuestion("");

    // Envoyer la question à GPT
    let messages = state.sessionStorage.find((session) => session.id == state.profilGptId).messages; // Element du tableau sessions Storage correspondant à la session en cours
    setWaiting(true);

    let reponseGPT = await askGptAjax({
      apiKeyId: state.apiKeyId,
      profilGptId: state.profilGptId,
      messages: messages.map((message) => {
        if (message.question) return { role: "user", content: message.question };
        else return { role: "system", content: message.reponse };
      }),
      model: state.model || "gpt-3.5-turbo",
      max_tokens: parseInt(state.max_tokens) || 4096,
      temperature: parseFloat(state.temperature) || 0,
      top_p: parseFloat(state.top_p) || 1,
      frequency_penalty: parseFloat(state.frequency_penalty) || 1,
      presence_penalty: parseFloat(state.presence_penalty) || -2,
      n: parseFloat(state.n) || 1,
    });
    setWaiting(false);
    reponseGPT = JSON.parse(reponseGPT);
    try {
      reponseGPT = reponseGPT.data.choices[0].message.content;
    } catch (error) {
      reponseGPT = "Une erreur est survenue, videz les messages du chat et réessayez";
    }
    // Si le nombre des messages de la sessions dépasse le nombre state.answer_max_length (si existe) alors on supprime le premier message
    if (state.answer_max_length && session.messages.length > parseInt(state.answer_max_length)) {
      // On supprimer les 2 premiers
      session.messages.shift();
      session.messages.shift();
    }
    session.messages.push({ reponse: reponseGPT });
    // Mettre à jour le store et la sessionStorage
    updStore({ ...state, sessionStorage: state.sessionStorage });
    sessionStorage.setItem("ocade-chatbots", JSON.stringify(state.sessionStorage));
  };

  const clearMessages = (event) => {
    event.preventDefault();
    const session = state.sessionStorage.find((session) => session.id == state.profilGptId);
    if (session) session.messages = [];
    else state.sessionStorage.push({ id: state.profilGptId, messages: [] });
    updStore({ ...state, sessionStorage: state.sessionStorage });
    sessionStorage.setItem("ocade-chatbots", JSON.stringify(state.sessionStorage));
  };

  const UserMessage = ({ question }) => {
    return (
      <div className="message message-user">
        <div className="wrapper-message">
          <p className="nom">{state.avatarClientNom || "Moi"}</p>
          <span className="question">{question}</span>
        </div>
        <Icon className="copy" title="Copier" backgroundImage={CopyUrl} backgroundColor="var(--info-fonce)" backgroundColorHover="var(--info-clair)" size="2rem" onClick={() => copyToClipboard(question)} />
        <span className="icon" style={{ "--background-image": `url('${ChatbotUrl}')` }}></span>
      </div>
    );
  };

  const SystemMessage = ({ reponse }) => {
    return (
      <div className="message message-system">
        <span className="icon" style={{ "--background-image": `url('${ChatbotUrl}')` }}></span>
        <div className="wrapper-message">
          <p className="nom">{state.avatarIaNom || "Service Client"}</p>
          <ReactMarkdown>{reponse}</ReactMarkdown>
          <Icon className="copy" title="Copier" backgroundImage={CopyUrl} backgroundColor="var(--success-fonce)" backgroundColorHover="var(--success-clair)" size="2rem" onClick={() => copyToClipboard(reponse)} />
        </div>
      </div>
    );
  };

  return (
    <div ref={chatbotRef} className={`plugins-ocade-chatbot ${props?.className || ""} ${state.isChatbotOpen ? "open" : ""}  ${state.isChatbotFullScreen ? "full-screen" : ""}`}>
      {hasAcceptedRgpd ? (
        <span className={`ombre ${isBegin ? "" : "no-is-begin"}`}>
          {questionsStarters()}

          <Button title="Fermer les propositions de démarrage" className="close-propositions" design="default" size="2rem" onClick={() => setIsBegin(true)}>
            Fermer
          </Button>
        </span>
      ) : null}

      <h1
        ref={h1Ref}
        className="h1"
        style={{
          "--background-image": `url(${ChatbotUrl})`,
        }}>
        {state.titleChat || "Messagerie"}
      </h1>
      <span
        className="avatar-principal"
        style={{
          "--background-image": `url("${ChatbotUrl}")`,
        }}
        alt="Chatbot"
        title={state.isChatbotOpen ? "Fermer le chatbot" : "Discuter avec le Chatbot ?"}
        onClick={openChatbot}></span>
      <span className="wrapper-icones">
        {isTextCopied && (
          <Button onClick={() => {}} className="message-text-copied" design="success">
            Texte Copié !
          </Button>
        )}
        {state.hasBoutonFullScreen && !state.isChatbotFullScreen && <Icon className="agrandir" title="Agrandir le Chatbot" backgroundImage={FullScreenUrl} backgroundColor="var(--info-fonce)" backgroundColorHover="var(--info-clair)" size="2rem" onClick={agrandirChatbot} />}

        {state.hasBoutonFullScreen && state.isChatbotFullScreen && <Icon className="reduire" title="Réduire le Chatbot" backgroundImage={ReduceScreenUrl} backgroundColor="var(--info-fonce)" backgroundColorHover="var(--info-clair)" size="2rem" onClick={reduireChatbot} />}

        <Icon className="close" title="Fermer le Chatbot" backgroundImage={DeleteUrl} backgroundColor="var(--error-fonce)" backgroundColorHover="var(--error-clair)" size="2rem" onClick={closeChatbot} />
      </span>

      <div className="wrapper-messages" ref={bottomRef}>
        {state.sessionStorage
          .find((session) => session.id == state.profilGptId)
          ?.messages.map((message, index) => {
            if (message.question) return <UserMessage key={index} question={message.question} />;
            else return <SystemMessage key={index} reponse={message.reponse} />;
          })}
        {waiting && (
          <div className="message message-system">
            <span className="icon" style={{ "--background-image": `url('${ChatbotUrl}')` }}></span>
            <div className="wrapper-message">
              <p className="nom">{state.avatarIaNom || "Service Client"}</p>
              <span className="reponse"></span>
            </div>
          </div>
        )}

        {showScrollToBottom && <Icon className="scroll-to-bottom" title="Descendre" backgroundImage={ArrowDownwardUrl} backgroundColor="var(--info-fonce)" backgroundColorHover="var(--info-clair)" size="2.2rem" onClick={scrollToBottom} />}
      </div>

      {hasAcceptedRgpd ? (
        <div ref={footerRef} className="wrapper-footer">
          <Textarea
            ref={textareaRef}
            className="input-message"
            placeholder={state.placeholderChat || ""}
            value={question}
            onChange={(question) => (question.target.value.length > parseInt(state?.question_max_length || 100) + 1 ? null : setQuestion(question.target.value))}
            onKeyDown={(event) => {
              if (event.key == "Enter") {
                event.preventDefault();
                askGPT();
              }
            }}
            style={{
              "--content": `"${question.length}/${state?.question_max_length || "xxx"}"`,
              "--before-color": question.length > state?.question_max_length ? "var(--error-fonce)" : "var(--info-fonce)",
            }}
          />

          {!waiting ? (
            question.length ? (
              <Button className="btn-envoyer" design="info" size="2rem" onClick={askGPT}>
                {state.boutonEnvoyer || "Envoyer"}{" "}
              </Button>
            ) : (
              <Button className="btn-clear" design="info" size="2rem" onClick={clearMessages}>
                {state.boutonNettoyer || "Nettoyer"}{" "}
              </Button>
            )
          ) : (
            <></>
          )}
        </div>
      ) : null}

      {!hasAcceptedRgpd && state.isChatbotOpen ? (
        <div className="rgpd-modal" style={{ display: 'flex', flexDirection: 'column' }}>
          <h2>Consentement à la collecte et au traitement des données personnelles sensibles</h2>
          <p>
            <i>En utilisant ce service, vous reconnaissez et acceptez que des données personnelles, y compris des données potentiellement sensibles, puissent être transmises et traitées par OpenAI. OpenAI traite vos données conformément à sa politique de confidentialité, disponible{" "}
            <a target="_blank" href="https://openai.com/fr-FR/policies/row-privacy-policy/">ici</a></i>
          </p>
          <div style={{ display: "flex", columnGap: "1rem", marginTop: "2rem" }}>
            <Button className="btn-accept-rgpd" onClick={handleAcceptRgpd}>
              Accepter
            </Button>
            <Button className="btn-reject-rgpd" onClick={handleRejectRgpd}>
              Refuser
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default App;
