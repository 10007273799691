import React, {forwardRef} from "react";

import "./Button.css";
import {Link} from "react-router-dom";

/**
 *
 * @param {string} children
 * @param {function} onClick
 * @param {string} className
 * @param {object} props
 * @returns {React.Component}
 *
 * @description Afficher un bouton
 *
 * @example
 * <Button onClick={() => console.log('Click !')}>Cliquez ici</Button>
 * <Button onClick={() => console.log('Click !')} className="maClasse">Cliquez ici</Button>
 */
const Button = forwardRef(
	(
		{className, isActive, linkPath, children, onClick, design, size, backgroundImage, backgroundColorIcon, backgroundColorIconHover, ...props},
		ref // C'est ici que la référence sera passée
	) => {
		const partageProps = {
			...props,
			ref: ref,
			className: `ocade-button ${className} ${isActive ? "is-active" : ""} design-${design} ${backgroundImage ? "has-background-image" : ""}`,
			onClick: onClick ? onClick : () => {},
			style: {
				"--background-image-url": backgroundImage ? `url(${backgroundImage})` : "",
				"--background-color-icon": backgroundColorIcon,
				"--background-color-icon-hover": backgroundColorIconHover,
				"--size": size
			}
		};

		return (
			<>
				{linkPath ? (
					<Link
						{...partageProps}
						to={linkPath}>
						{children}
					</Link>
				) : (
					<button {...partageProps} >
						{children}
					</button>
				)}
			</>
		);
	}
);

Button.defaultProps = {
	className: "",
	isActive: false,
	linkPath: false,
	design: "info",
	size: "1rem",
	backgroundImage: false,
	backgroundColorIcon: "var(--color-blanc)",
	backgroundColorIconHover: "var(--color-blanc)",
	onClick: () => {
		console.log('Vous devez renseigner "onClick" sur le composant Button !');
	},
	children: 'Aucun "children" renseigné !'
};

export default Button;
