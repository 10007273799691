// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style pour le composant Textarea, similaire à celui de Input */
.form-group textarea {
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  z-index: 1;
  display: block;
  flex: 1 1 auto;
  width: 100%; /* Adapté pour Textarea */
  margin: 0 0 0 -1px;
  padding: 8px 16px;
  color: var(--input-color);
  background-color: var(--input-background);
  border: 1px solid var(--input-border);
  border-radius: 0 6px 6px 0;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  line-height: 25px; /* Peut être ajustée pour Textarea */
  transition: all 0.3s ease;
  resize: vertical; /* Permet aux utilisateurs de redimensionner le textarea verticalement */
  overflow: auto; /* Assure une bonne gestion du débordement de texte */
}

.form-group textarea:hover {
  border-color: var(--input-border-focus);
  cursor: text; /* Cursor 'text' est plus approprié pour textarea */
}

.form-group textarea:focus {
  border-color: var(--input-border-focus);
  outline: none;
  box-shadow: none;
  cursor: initial;
}

.form-group textarea::placeholder {
  color: var(--input-placeholder);
}
`, "",{"version":3,"sources":["webpack://./src/composants/Textarea/Textarea.css"],"names":[],"mappings":"AAAA,iEAAiE;AACjE;EACE,gBAAgB;EAChB,wBAAwB;EACxB,kBAAkB;EAClB,UAAU;EACV,cAAc;EACd,cAAc;EACd,WAAW,EAAE,yBAAyB;EACtC,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB;EACzB,yCAAyC;EACzC,qCAAqC;EACrC,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,iBAAiB,EAAE,oCAAoC;EACvD,yBAAyB;EACzB,gBAAgB,EAAE,wEAAwE;EAC1F,cAAc,EAAE,qDAAqD;AACvE;;AAEA;EACE,uCAAuC;EACvC,YAAY,EAAE,mDAAmD;AACnE;;AAEA;EACE,uCAAuC;EACvC,aAAa;EACb,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":["/* Style pour le composant Textarea, similaire à celui de Input */\n.form-group textarea {\n  appearance: none;\n  -webkit-appearance: none;\n  position: relative;\n  z-index: 1;\n  display: block;\n  flex: 1 1 auto;\n  width: 100%; /* Adapté pour Textarea */\n  margin: 0 0 0 -1px;\n  padding: 8px 16px;\n  color: var(--input-color);\n  background-color: var(--input-background);\n  border: 1px solid var(--input-border);\n  border-radius: 0 6px 6px 0;\n  font-size: 14px;\n  font-weight: 500;\n  font-family: inherit;\n  line-height: 25px; /* Peut être ajustée pour Textarea */\n  transition: all 0.3s ease;\n  resize: vertical; /* Permet aux utilisateurs de redimensionner le textarea verticalement */\n  overflow: auto; /* Assure une bonne gestion du débordement de texte */\n}\n\n.form-group textarea:hover {\n  border-color: var(--input-border-focus);\n  cursor: text; /* Cursor 'text' est plus approprié pour textarea */\n}\n\n.form-group textarea:focus {\n  border-color: var(--input-border-focus);\n  outline: none;\n  box-shadow: none;\n  cursor: initial;\n}\n\n.form-group textarea::placeholder {\n  color: var(--input-placeholder);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
