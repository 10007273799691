import React, { createContext, useState, useContext, useEffect } from "react";

// Créer un nouveau contexte pour le store
export const StoreContext = createContext(null);

// Créer un hook personnalisé pour utiliser le store
export function useStore() {
  return useContext(StoreContext);
}

const store = {
  isChatbotOpen: false,
  isChatbotFullScreen: false,
  sessionStorage: [] // Contient les messages du chatbot
};

// // Créer un fournisseur pour le store
export const StoreProvider = ({ children }) => {
  const [state, setState] = useState(store);

  const initStore = (store) => setState(store);
  const updStore = (store) => setState({ ...state, ...store });

  /********************** Toutes les fonctions de CRUD *******************************/
  const FctStore = {
    initStore,
    updStore
  };

  /********************** Renvoie le Store mise à jour dans l'app ********************/
  return (
    <StoreContext.Provider value={{ state, ...FctStore }}>
      {children}
    </StoreContext.Provider>
  );
};
