import React from 'react';
import { createRoot } from 'react-dom/client';
import { StoreProvider } from './Store';
import App from './App';

document.addEventListener('DOMContentLoaded', () => {
  const blockContainers = document.querySelectorAll('[id^="root-ocade-chatbot-"]');
  if (!blockContainers) return;

  blockContainers.forEach(container => {
    const root = createRoot(container);
    // Récupérer les attributs de données passés du serveur PHP (Render Block PHP) à l'application React.
    const attributes = JSON.parse(container.getAttribute('data-attributes'));

    // Rendre le composant racine dans chaque racine React avec les attributs comme props
    root.render(
      <StoreProvider>
        <App {...attributes} />
      </StoreProvider>
    );
  });
});
