import React, { forwardRef } from "react";
import "./Textarea.css";

/**
 * Composant Textarea
 *
 * @param {string} className
 * @param {string} label
 * @param {string} name
 * @param {string} placeholder
 * @param {string} value
 * @param {function} onChange
 * @param {object} props
 * @returns {React.Component}
 *
 * @description Afficher un champ de saisie de texte multi-lignes
 *
 * @example
 * <Textarea label="Votre Message" name="message" placeholder="Ecrivez quelque chose..." value="" onChange={() => console.log('Texte modifié !')} />
 * <Textarea label="Votre Message" name="message" placeholder="Ecrivez quelque chose..." value="" onChange={() => console.log('Texte modifié !')} className="maClasseTextarea" />
 */
const Textarea = forwardRef(
  ({ className, label, name, placeholder, value, onChange, ...props }, ref) => {

    return (
      <div {...props} className={`form-group ${className}`}>
        <span>{label}</span>
        <textarea
          ref={ref}
          className="form-field"
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    );
  }
);

Textarea.defaultProps = {
  className: "",
  label: "label",
  name: "textarea",
  placeholder: "Placeholder...",
  value: "",
  onChange: () => {}
};

export default Textarea;
